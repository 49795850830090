//Direktkauf

.list_se {
    > li.inview {
        min-width: 236px;

        > div {
            width: 236px;
        }

        .hover-wk {
            display: none !important;
        }

        &.direktkauf-pos-links {
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);

            .hover-wk-groessen {
                position: absolute;
                left: -190px;
                right: 0;
                top: -20px;
                z-index: -1;
                background: #fff;
                padding: 20px 0;
                box-sizing: content-box;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
            }
        }

        &.direktkauf-pos-rechts {
            box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);

            .hover-wk {
                right: 6px;
            }

            .hover-wk-groessen {
                animation: slide-r2l 300ms;
                transform-origin: 100% 50%;
            }
        }

        &.hovered {
            position: sticky;

            //min-width: 418px;
            z-index: 200;

            //display: flex;
            //width: 432px;
            transition: all 300ms ease-in-out;
            overflow: visible;

            > div {
                background-color: #fff;
                border-radius: 5px;
            }

            &.direktkauf-pos-links {
                //flex-direction: row-reverse;
                //margin-left: -200px;

                .miniSlNav {
                    &.next {
                        right: 6px;
                    }

                    &.prev {
                        left: 16px;
                    }
                }

                .hover-wk-groessen {
                    position: absolute;
                    left: -190px;
                    right: 0;
                    top: -20px;
                    z-index: -1;
                    background: #fff;
                    padding: 20px 0;
                    box-sizing: content-box;
                    border-radius: 0;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    box-shadow: -2px 2px 3px 0px rgba(0, 0, 0, 0.1);
                    animation: slide-r2l 300ms;
                    transform-origin: 100% 50%;
                }
            }

            &.direktkauf-pos-rechts {
                .hover-wk-groessen {
                    position: absolute;
                    left: 230px;
                    right: 0;
                    top: -20px;
                    z-index: -1;
                    background: #fff;
                    padding: 20px 0;
                    box-sizing: content-box;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);
                    animation: slide-l2r 300ms;
                    transform-origin: 0% 50%;
                }
            }

            .hover-wk-groessen {
                min-width: 195px;
            }

            .hover-wk {
                border-radius: 0;
            }
        }

        // Container für Hover-Direktkauf
        .hover-wk-groessen {
            display: none;
            flex-direction: column;
            align-items: center;

            //min-width: 155px;
            //max-width: 210px;
            padding: 0 10px;
            margin: 20px 0;
            height: calc(100% - 40px);
            box-sizing: border-box;
            color: $blau;
            width: 0;

            button {
                padding: 0 10px;
                font-size: 12px;
                min-width: 150px;
            }

            .direktkauf-wrapper {
                height: 100%;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                flex-direction: column;

                .direktkauf-headline {
                    color: $blau;
                }

                .direktkauf-buttons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;

                    &.viele-groessen {
                        flex-direction: row;
                    }

                    .direktkauf-groessenbutton {
                        color: $blau;
                        border: 2px solid $dgrau;
                        border-radius: 5px;
                        margin: 3px;
                        padding: 5px;
                        cursor: pointer;
                        width: 60px;
                        height: 25px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &.active {
                            border: 2px solid $blau;
                        }
                    }
                }

                .direktkauf-button-wrapper {
                    max-height: 225px;
                    overflow-y: hidden;
                }
            }
        }

        @keyframes hover-slide {
            0% {
                transform: translateX(-30px);
            }

            100% {
                transform: translateX(0px);
            }
        }

        @keyframes slide-r2l {
            0% {
                transform: translateX(190px);
                opacity: 0;
            }

            100% {
                transform: translateX(0px);
                opacity: 1;
            }
        }

        @keyframes slide-l2r {
            0% {
                transform: translateX(-190px);
                opacity: 0;
            }

            100% {
                transform: translateX(0px);
                opacity: 1;
            }
        }
    }
}

@media (max-width: 760px) {
    .hover-wk {
        display: none !important;
    }
}
